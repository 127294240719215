import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { ADMIN /*,OPERATION,MERCHANT*/ } from "../roles";
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userType = useSelector((state) => state.auth.userType);

  const location = useLocation();
  console.log(location.pathname);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if ((userType !== ADMIN ) && location.pathname !== "/Operation") {
    return <Navigate to="/Operation" />;
  }
  return children;
};

export default PrivateRoute;
