import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  // useCallback,
} from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { TransactionStatus } from "../TransactionStatus";
import {
  MERCHANT_ID_MAP_BASE_URL,
  SEARCH_BY_DETAIL_BASE_URL,
  SEARCH_BY_SESSIONID_BASE_URL,
  SEARCH_BY_NONCE_BASE_URL,
  // MAKE_FAILED_BASE_URL,
  // UPDATE_BASE_URL,
  // NOTIFICATION_BASE_URL,
  // LOGIN_URL,
} from "./endpoint";
// import store from "../store";
import { /*useDispatch,*/ useSelector } from "react-redux";
import {
  Select,
  MenuItem,
  Box,
  Alert,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  // useTheme,
  Autocomplete,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { /* OPERATION, */ ADMIN, MERCHANT } from "../roles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Grid } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import SendIcon from "@mui/icons-material/Send";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Sidebar from "../Component/Bar/Sidebar";
import Header from "../Component/Header";
import LinearProgress from "@mui/material/LinearProgress";
// import { tokens } from "../theme";

const Operation = () => {
  // console.log(store.getState());
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  // let loading=false;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [editableTransactionID, setEditableTransactionID] = useState(null);
  const [searchType, setSearchType] = useState("sessionid");
  const [editedTransactionIds, setEditedTransactionIds] = useState({});
  const [Merchants, setMerchants] = useState([]);
  // const accessToken = useSelector((state) => state.auth.userType);
  const [userId, setUserId] = useState("");
  const { username, userType, password } = useSelector((state) => state.auth);


  const [formValues, setFormValues] = useState({
    sessionId: "",
    nonce: "",
    phone: "",
    userId: "",
    amount: "",
    pickerDate: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [teleSideStatus, setTeleSideStatus] = useState([]);
  function addTeleSideStatus(id, TPstatus) {
    setTeleSideStatus(prevStatus => [...prevStatus, { id, TPstatus }]);
  }
  function updateTeleSideStatusById(id, newTPstatus) {
    setTeleSideStatus(prevStatus =>
      prevStatus.map(item =>
        item.id === id
          ? { ...item, TPstatus: newTPstatus }
          : item
      )
    );
  }

  function getTeleSideStatusById(id) {
    const result = teleSideStatus.find(item => item.id === id);
    return result || null;
  }
  function resetTeleSideStatus() {
    setTeleSideStatus([]);
  }
  async function handleClick(row) {
    // alert(row.session)
    
    
      try {
        if (getTeleSideStatusById(row.id).TPstatus === TransactionStatus.PENDING || true ) {
          const response = await axios.post('https://gateway.arifpay.net/p3/telebirr-ussd/query-transactionBySessionId', {
            ref: row.session
          });
           console.log(response.data.data);
           
          if (response.data.data.transactionStatus === "Completed" ) {
  
            setSnackbar({
              open: true,
              message: `found Successfully ${response.data.data.transactionId}`,
              severity: "success",
            });
          } else {
            setSnackbar({
              open: true,
              message: "The Transaction could not be found. Please check manually.",
              severity: "warning",
            });
          }
  
  
  
          // setResponseData(data); // Update the state with the response data after testing the new feature will add when the transaction id was't null set the setEditableTransactionID(row.id);
          //  and set the  handleBlur (row.id, response.data.data.transactionId) for now let's test

        }
       


      } catch (error) {

        setSnackbar({
          open: true,
          message: "Error fetching data",
          severity: "error",
        });
      }

    
  }
  function getIconByStatus(status) {
    if (status === TransactionStatus.SUCCESS) {
      return <CheckCircleOutlineIcon sx={{
        color: 'green',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)',
        },
      }} />
    } else if (status === TransactionStatus.PENDING) {
      return <CachedIcon sx={{
        color: '#4A64C2', cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)',
        },
      }} />
    } else {
      return <ErrorOutlineIcon sx={{
        color: 'red', cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.2)',
        },
      }} />
    }
  }
  function initializeTeleSideStatus(rows) {
    const filteredStatus = rows
      .filter(row => row.paymentType === "4" || row.paymentType === "13")
      .map(row => ({
        id: row.id,
        TPstatus: row.status
      }));
    setTeleSideStatus(filteredStatus);
  }
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const paymentTypes = [
    { value: 1, label: "ACCOUNT" },
    { value: 2, label: "NONYMOUS_ACCOUNT" },
    { value: 3, label: "ANONYMOUS_CARD" },
    { value: 4, label: "TELEBIRR" },
    { value: 5, label: "AWASH" },
    { value: 6, label: "AWASH_WALLET" },
    { value: 7, label: "PSS" },
    { value: 8, label: "CBE" },
    { value: 9, label: "AMOLE" },
    { value: 10, label: "BOA" },
    { value: 11, label: "KACHA" },
    { value: 12, label: "ETHSWITCH" },
    { value: 13, label: "TELEBIRR_USSD" },
    { value: 14, label: "HELLOCASH" },
    { value: 15, label: "MPESA" },
  ];

  const sessionIdRef = useRef(null);
  const nonceRef = useRef(null);
  const phoneRef = useRef(null);
  const handleFetchError = (message, error) => {
    // console.error(message, error);

    setSnackbar({
      open: true,
      message: "Something went wrong",
      severity: "error",
    });
  };
  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await axios.get(MERCHANT_ID_MAP_BASE_URL);
        setMerchants(response.data);
      } catch (error) {
        handleFetchError("Error fetching merchants:", error);
      }
    };
    if (userType !== MERCHANT) {
      fetchMerchants();
    }
  }, []);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  function standardizePhoneNumber(phone) {
    console.log(phone)
    let digits = phone.replace(/\D/g, '');
    if (digits.startsWith('0')) {
      console.log('251' + digits.slice(1))
      return '251' + digits.slice(1);
    }
    if (digits.length === 9) {
      console.log('251' + digits)
      return '251' + digits;
    }
    return digits;
  }
  useEffect(() => {
    if (searchType === "sessionid") sessionIdRef.current.focus();
    else if (searchType === "nonce") nonceRef.current.focus();
    else if (searchType === "detail") phoneRef.current.focus();
  }, [searchType]);
  const handleChange = (event, newValue) => {
    //  console.log(newValue.value || null)
    setUserId(newValue ? newValue.value : null);
  };
  const handleSearchTypeChange = (e) => setSearchType(e.target.value);
  const handleInputChange = (e) =>
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  const handleDateChange = (newDate) => {
    setFormValues({ ...formValues, pickerDate: newDate });
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });
  const handleDoubleClick = (row) => {



    if (!row.transactionId && row.status === TransactionStatus.PENDING) {
      setEditableTransactionID(row.id);
    }
    if (row.transactionId === row.session && row.status === TransactionStatus.PENDING && row.paymentType === '4') {
      setEditableTransactionID(row.id);
    }
  };

  const handleBlur = (rowId, newTransactionId) => {
    if (newTransactionId !== "null") {
      setEditedTransactionIds((prevState) => ({
        ...prevState,
        [rowId]: newTransactionId.trim().toUpperCase(),
      }));
      setEditableTransactionID(null);
    }
  };

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
  };

  const fetchBySessionid = async (sessionId) => {
    setLoading(true)

    try {
      const response = await axios.get(
        SEARCH_BY_SESSIONID_BASE_URL,
        {
          params: { uuid: sessionId.trim() },
        }
      );

      if (response.status === 200) {
        if (response.data.length === 0) {
          setSnackbarMessage(`No transaction Found By ${sessionId}`);
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);
        } else {
          initializeTeleSideStatus(response.data);
          setRows(response.data);

        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setSnackbarMessage(`Error fetching data by Session ID`);
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);

      handleFetchError("Error fetching data by Session ID:", error);
    } finally {
      setLoading(false)
    }
  };
  const formulateDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const fetchByNonce = async (nonce) => {
    setLoading(true)

    try {
      const response = await axios.get(
        SEARCH_BY_NONCE_BASE_URL,
        {
          params: { nonce: nonce.trim() },
          // headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        if (response.data.length === 0) {
          setSnackbarMessage(`No transaction Found By ${nonce}`);
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);
        } else {
          initializeTeleSideStatus(response.data);
          setRows(response.data);

        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {

      handleFetchError("Error fetching data by Nonce:", error);
    } finally {
      setLoading(false)
    }
  };
  const fetchByDetail = async (details) => {
    details.userId = userId;
    setLoading(true)


    try {
      const response = await axios.get(
        SEARCH_BY_DETAIL_BASE_URL,

        {
          params: {
            phone: standardizePhoneNumber(details.phone.trim()),
            userId: details.userId,
            amount: details.amount.trim(),
            date: details.pickerDate.format("YYYY-MM-DD"),
            username: username,
            password: password,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.length === 0) {
          setSnackbarMessage(`No transaction Found By This Detail`);
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);

        } else {
          initializeTeleSideStatus(response.data);
          setRows(response.data);


        }
      } else {

        throw new Error("Failed to fetch data");

      }
    } catch (error) {
      handleFetchError("Error fetching data by Detail:", error);
    } finally {
      setLoading(false)
    }
  };
  const updateRowState = (rowId, status, transactionId) => {
    const updatedRows = rows.map((transaction) =>
      transaction.id === rowId
        ? { ...transaction, transactionId: transactionId, status }
        : transaction
    );
    initializeTeleSideStatus(updatedRows);
    setRows(updatedRows);

  };

  const handleSearch = () => {
    setRows([]);
    resetTeleSideStatus()



    const { sessionId, nonce, phone, userId, amount, pickerDate } = formValues;
    if (searchType === "sessionid") {
      fetchBySessionid(sessionId);
    } else if (searchType === "nonce") {
      fetchByNonce(nonce);
    } else if (searchType === "detail") {
      fetchByDetail({ phone, userId, amount, pickerDate });
    }

    setEditedTransactionIds({});

  };

  const updateTransactionFailed = async (row, status) => {
    try {
      const session = row.session;
      const CurrentPaymentType = getLabelByValuePT(parseInt(row.paymentType))
      const url = `https://gateway.arifpay.net/api/verify/transaction/update/${session}`;
      const payload = {
        paymentType: CurrentPaymentType,
        status: status,
        userName: username,
        password: password,
      };
      // Make A put request
      const response = await axios.put(url, payload);
      if (response.status === 200) {
        // console.log("Transaction status updated successfully", response.data);
        updateRowState(row.id, TransactionStatus.FAILED, "null");
        setSnackbar({
          open: true,
          message: "Change to Failed",
          severity: "success",
        });
      } else {
        console.error("Unexpected response status:", response.status);
        throw new Error(
          "Failed to update transaction status due to unexpected response status"
        );
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        // Server responded with a status other than 2**
        console.error("Server error:", error.response.data);
        throw new Error(
          `Failed to update transaction status: ${error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        // No response was received
        console.error("No response received:", error.request);
        throw new Error(
          "Failed to update transaction status: No response from server"
        );
      } else {
        // Other errors
        console.error("Error:", error.message);
        throw new Error(
          `Failed to update transaction status: ${error.message}`
        );
      }
    }
  };

  const updateTransaction = async (row, transactionId, status) => {
    try {
      const session = row.session;
      const CurrentPaymentType = getLabelByValuePT(parseInt(row.paymentType));
      const url = `https://gateway.arifpay.net/api/verify/transaction/update/${session}`;
      const payload = {
        paymentType: CurrentPaymentType,
        transactionId: transactionId,
        status: status,
        userName: username,
        password: password,
      };

      // Make A put request
      const response = await axios.put(url, payload);
      if (response.status === 200) {
        console.log("Transaction status updated successfully", response.data);
        updateRowState(row.id, TransactionStatus.FAILED, "null");
        setSnackbar({
          open: true,
          message: "Change to successfully",
          severity: "success",
        });
      } else {
        console.error("Unexpected response status:", response.status);
        throw new Error(
          "Failed to update transaction status due to unexpected response status"
        );
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        // Server responded with a status other than 2**
        console.error("Server error:", error.response.data);
        throw new Error(
          `Failed to update transaction status: ${error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        // No response was received
        console.error("No response received:", error.request);
        throw new Error(
          "Failed to update transaction status: No response from server"
        );
      } else {
        // Other errors
        console.error("Error:", error.message);
        throw new Error(
          `Failed to update transaction status: ${error.message}`
        );
      }
    }
  };

  const sendNotification = async (session) => {
    try {
      const response = await axios.post(
        `https://gateway.arifpay.net/api/verify/trigger/notify/${session}`,
        {
          userName: username,
          password: password,
        }
      );
       
  

      if (response.status === 200) {
        console.log("Notification sent successfully");
        setSnackbar({
          open: true,
          message: "Notification sent Successfully",
          severity: "success",
        });
      } else {

        setSnackbar({
          open: true,
          message: `Failed to send notification. Please try again `,
          severity: "warning",
        });
      }

    } catch (error) {
     
      if (error.response.data.error) {
        if (error.response.status === 404) {
          console.log("error.response.data.msg")
          setSnackbar({
            open: true,
            message: `${error.response.data.msg}`,
            severity: "warning",
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: `${error.message}`,
          severity: "warning",
        });
      }


      // // setSnackbar({ open: true, message: error.message, severity: "error" });
      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2**
      //   console.error("Error response data: ", error.response.data);
      //   console.error("Error response status: ", error.response.status);
      //   console.error("Error response headers: ", error.response.headers);

      // } else if (error.request) {
      //   // The request was made but no response was received
      //   console.error("No response received: ", error.request);
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.error("Error message: ", error.message);
      // }

    }
  };

  const handleAction = async (row, action) => {
    setSending(true)
    try {
      if (action === TransactionStatus.FAILED) {
        await handleFailedAction(row);
      } else if (action === "send") {
        await handleSendAction(row);
      }
    } catch (error) {
      const errorMessage =
        action === TransactionStatus.FAILED
          ? "Error updating data and sending notification."
          : "Error sending notification.";
      setSnackbar({ open: true, message: errorMessage, severity: "error" });
    } finally {
      setSending(false)

    }
  };

  const handleFailedAction = async (row) => {
    try {
      if (row.status !== TransactionStatus.SUCCESS) {
        await updateTransactionFailed(row, TransactionStatus.FAILED);
      } else {
        setSnackbar({
          open: true,
          message: "You can't make a successful transaction failed",
          severity: "error",
        });
      }
    } catch (error) {
      handleFetchError("Error handling failed action:", error);
    } finally {
      // setLoading(false)
    }
  };

  const handleSendAction = async (row) => {
    //  console.log((row))
    try {
      if (
        editedTransactionIds[row.id] &&
        row.status === TransactionStatus.PENDING
      ) {
        await updateTransaction(
          row,
          editedTransactionIds[row.id],
          TransactionStatus.SUCCESS
        );
        updateRowState(
          row.id,
          TransactionStatus.SUCCESS,
          editedTransactionIds[row.id]
        );
        await sendNotification(row.session);

      } else if (row.status !== TransactionStatus.PENDING) {

        await sendNotification(row.session);

      }

      else {
        setSnackbar({
          open: true,
          message: "You cannot send a notification for a pending transaction",
          severity: "warning",
        });
      }
    } catch (error) {
      handleFetchError("Error handling send action:", error);
      setSnackbar({
        open: true,
        message: error.msg,
        severity: "warning",
      });
    }
  };

  const sortedRows = useMemo(() => {
    return [...rows].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [rows, sortConfig]);

  const getLabelByValue = (value) => {
    const merchant = Merchants.find((merchant) => merchant.value === value);
    return merchant ? merchant.label : "Unknown";
  };
  const getLabelByValuePT = (value) => {
    const paymenttype = paymentTypes.find((type) => type.value === value);
    return paymenttype ? paymenttype.label : "Unknown";
  };
  const failedButtonDisabled = (status) => {
    if (status === TransactionStatus.PENDING) {
      return false;
    } else if (status === TransactionStatus.SUCCESS) {
      return true;
    }else if (status === TransactionStatus.EXPIRED) {
      return true;
    }
  };
  const sendButtonDisabled = (row, inEdited) => {
    // console.log(row.transactionId !== row.session)
    if (row.status === TransactionStatus.SUCCESS) {
      return false;
    } else if (row.status === TransactionStatus.PENDING) {
      return !inEdited;
    } else if (row.status === TransactionStatus.FAILED) {
      return false;
    }
  };

  // console.log(store.getState());
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Operation" subtitle="" />
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            // sx={{ background: "red", spacing: 4 }}
            justifyContent="start"
            alignItems="flex-start"
          >
            <Grid item xs={2} sm={3} md={4} lg={2}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="searchType">
                  Search Type
                </InputLabel>
                <NativeSelect
                  value={searchType}
                  onChange={handleSearchTypeChange}
                  inputProps={{ name: "searchType", id: "searchType" }}
                >
                  <option value="sessionid">Session ID</option>
                  <option value="nonce">Nonce</option>
                  <option value="detail">Detail</option>
                </NativeSelect>
              </FormControl>
            </Grid>

            {/* <Grid item xs={10} sm={9} md={8} lg={10} sx={{ background:"red" }}>
              s
            </Grid> */}

            {searchType === "sessionid" && (
              <>
                <Grid item xs={5} sm={3} md={4} lg={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="Session ID"
                    name="sessionId"
                    value={formValues.sessionId}
                    inputRef={sessionIdRef}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={8}>
                  {" "}
                </Grid>
              </>
            )}

            {searchType === "nonce" && (
              <>
                <Grid item xs={5} sm={3} md={4} lg={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label="Nonce"
                    name="nonce"
                    value={formValues.nonce}
                    inputRef={nonceRef}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={8}>
                  {" "}
                </Grid>
              </>
            )}

            {searchType === "detail" && (
              <>
                <Grid item xs={3} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Phone Number"
                    name="phone"
                    value={formValues.phone}
                    inputRef={phoneRef}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={4} sm={0} md={0} lg={1}>
                  {" "}
                </Grid>
                {userType !== MERCHANT && (
                  <Grid item xs={4} sm={5} md={4} lg={2}>
                    <Autocomplete
                      id="outlined-userId"
                      fullWidth
                      options={Merchants}
                      getOptionLabel={(option) => option.label}
                      value={
                        Merchants.find((option) => option.value === userId) ||
                        null
                      }
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-userId"
                          variant="standard"
                          label="User ID"
                          placeholder="Search or select..."
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={5} sm={0} md={1} lg={0}>
                  {" "}
                </Grid>
                <Grid item xs={4} sm={6} md={4} lg={2}>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Amount"
                    name="amount"
                    value={formValues.amount}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={8} sm={1} md={1} lg={1}></Grid>
                <Grid
                  p={"10px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item
                  xs={8}
                  sm={12}
                  md={6}
                  lg={4}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      label="Select Date"
                      value={formValues.pickerDate}
                      onChange={handleDateChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  //  sx={{ background: "red" }}
                  item
                  xs={1}
                  sm={1}
                  md={4}
                  lg={7}
                ></Grid>
              </>
            )}
            {/* BTN */}
            <Grid item xs={4} sm={6} md={4} lg={2}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<SearchIcon />}
                onClick={handleSearch}
                fullWidth
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead
            // sx={{ background: "#757575" }}
            >
              <TableRow>
                {sortedRows.some(row => row.paymentType === "4" || row.paymentType === "13") && <TableCell align="center">TP Side Status</TableCell>}
                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "id" ? sortConfig.direction : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "id"}
                    direction={
                      sortConfig.key === "id" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("id")}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "created_at"
                      ? sortConfig.direction
                      : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "created_at"}
                    direction={
                      sortConfig.key === "created_at"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("created_at")}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "transactionId"
                      ? sortConfig.direction
                      : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "paymentType"}
                    direction={
                      sortConfig.key === "paymentType"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("paymentType")}
                  >
                    Payment Type
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "transactionId"
                      ? sortConfig.direction
                      : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "transactionId"}
                    direction={
                      sortConfig.key === "transactionId"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("transactionId")}
                  >
                    Transaction ID
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "amount" ? sortConfig.direction : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "amount"}
                    direction={
                      sortConfig.key === "amount" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("amount")}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>

                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "session_id"
                      ? sortConfig.direction
                      : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "session_id"}
                    direction={
                      sortConfig.key === "session_id"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("session_id")}
                  >
                    Session Id
                  </TableSortLabel>
                </TableCell>

                <TableCell align="center"
                  sx={{ fontWeight: "bold" }}
                  sortDirection={
                    sortConfig.key === "status" ? sortConfig.direction : false
                  }
                >
                  <TableSortLabel
                    active={sortConfig.key === "status"}
                    direction={
                      sortConfig.key === "status" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                {userType !== MERCHANT && (
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Merchant
                  </TableCell>
                )}
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Fix
                </TableCell>
                {userType === ADMIN && (
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {" "}
                    Make It Failed
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {!loading && (
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.id}>

                    {row.paymentType === '4' || row.paymentType === '13' ? <TableCell align="center" onClick={() => handleClick(row)}>{getIconByStatus(getTeleSideStatusById(row.id).TPstatus)}</TableCell> : ''}
                    {/*  */}
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{formulateDate(row.createdAt)}</TableCell>
                    <TableCell align="center">
                      {getLabelByValuePT(parseInt(row.paymentType))}
                    </TableCell>
                    <TableCell align="center" onDoubleClick={() => handleDoubleClick(row)}>
                      {editableTransactionID === row.id ? (
                        <TextField
                          defaultValue={row.transactionId}
                          onBlur={(e) => handleBlur(row.id, e.target.value)}
                          autoFocus
                        />
                      ) : (
                        editedTransactionIds[row.id] ||
                        row.transactionId ||
                        "null"
                      )}
                    </TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    <TableCell align="center">{row.session}</TableCell>

                    <TableCell align="center">{row.status}</TableCell>
                    {userType !== MERCHANT && (
                      <TableCell align="center">{`${getLabelByValue(row.user)} ${row.user
                        }`}</TableCell>
                    )}

                    <TableCell align="center">
                      {sending ? <CircularProgress /> : <Button
                        variant="contained"
                        disabled={sendButtonDisabled(
                          row,
                          editedTransactionIds.hasOwnProperty(row.id)
                        )}
                        // disabled={
                        //   row.status !== "Success" &&
                        //   !editedTransactionIds.hasOwnProperty(row.id)
                        //     ? true
                        //     : false
                        // }
                        color="success"
                        startIcon={<SendIcon />}
                        onClick={() => handleAction(row, "send")}
                      >
                        Send
                      </Button>}


                    </TableCell>
                    {userType === ADMIN && (
                      <TableCell align="center">
                        <Button
                          disabled={failedButtonDisabled(row.status)}
                          // disabled={row.status !== "Pending" ? true : false}
                          variant="contained"
                          color="error"
                          startIcon={<SmsFailedIcon />}
                          onClick={() =>
                            handleAction(row, TransactionStatus.FAILED)
                          }
                        >
                          Failed
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>

          {loading && <LinearProgress color="secondary" />}
        </TableContainer>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Operation;
