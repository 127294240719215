import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function DrawerItem({ text, icon, navigate, open }) {
  return (
    <ListItem  disablePadding sx={{ display: 'block' ,background:text==="Setting"? '#2A9EB7':"",color:text==="Setting"? '#FFFFFF':""}} onClick={navigate}>
      <ListItemButton
        sx={{ 
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            color:text==="Setting"? '#FFFFFF':"",
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}
