// import { LOGIN,LOG } from "./actionTypes";
import {LOGOUT, LOGIN } from "./actionTypes";

export const login = (username, password, userType,token,apiKey) => ({
  type: LOGIN,
  payload: {
    username,
    password,
    userType,
    // token,
    // apiKey,
  },
});

export const logout = () => ({
  type: LOGOUT,
});
