
export const MERCHANT_ID_MAP_BASE_URL="https://cms.arifpay.org/merchants-id-maps"


export const LOCAL_BASE_URL="http://localhost:5000/api"
export const LOGIN_URL='http://localhost:5000/login'

export const BASE_URL="https://gateway.arifpay.net/api"
export const SEARCH_BY_DETAIL_BASE_URL=`${BASE_URL}/verify/transaction/details` 
export const SEARCH_BY_SESSIONID_BASE_URL=`${BASE_URL}/verify/transaction`
export const SEARCH_BY_NONCE_BASE_URL=`${BASE_URL}/verify/transaction/nonce`
export const MAKE_FAILED_BASE_URL=`${BASE_URL}/makeFailed`
export const UPDATE_BASE_URL=`${BASE_URL}/update`
export const NOTIFICATION_BASE_URL=`${BASE_URL}/notification`
