import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomAppBar from './AppBar';
import CustomDrawer from './Drawer';

export default function Sidebar() {
 
  const [open, setOpen] = React.useState(true);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(!open);
  }; 

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <CustomAppBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        auth={auth}
        handleChange={handleChange}
        anchorEl={anchorEl}
        handleMenu={handleMenu}
        handleClose={handleClose}
      />
      <CssBaseline />
      <CustomDrawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        navigate={navigate}
      />
    </Box>
  );
}
