import "./App.css";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
 
// import {  useSelector } from "react-redux";
 
import Operation from "./Page/Operation";
import { Navigate } from 'react-router-dom';
 
import PrivateRoute from "./Component/PrivateRoute";
import { Routes, Route, BrowserRouter } from "react-router-dom";
 

import Login from "./Page/Login";
function App() {

  const [theme, colorMode] = useMode();
  
  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div className="app bg-indigo-500 ">
          <main className="content bg-indigo-500 ">
            <Routes>
              
            <Route path="/login" element={<Login />} />
                <Route path="/operation/*" element={<PrivateRoute><Operation /></PrivateRoute>} />
                <Route path="*" element={<Navigate to="/login" />} />
              
              
            </Routes>
          </main>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  </ColorModeContext.Provider>
  
  );
}




export default App;
