// src/reducers/authReducer.js
import {
  LOGIN,
  LOGOUT
} from '../actions/actionTypes';


const initialState = {
  isAuthenticated: false,
  username: '',
  userType: null,
  password: '',
  token: '',
  apiKey: ''
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
          username: action.payload.username,
          userType: action.payload.userType,
          password: action.payload.password,
          // token: action.payload.token,
          // apiKey: action.payload.apiKey,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;